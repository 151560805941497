export default {
  common: {
    field: {
      email: 'Email',
      emailPlaceholder: 'Enter your email',
      password: 'Password',
      passwordPlaceholder: 'Enter your password...',
      passwordHelp: 'At least 8 characters, 1 number, UPPERCASE letter, and special characters (!@#$%...) are required.',
      fullName: 'Full name',
      fullNamePlaceholder: 'Enter your name...',
      code: 'Code',
      previewOnly: 'Preview only 👀',
      typeYourAnswer: 'Type your answer here...',
      enter: 'Enter ↵',
      press: 'press ',
      sayHiHere: 'Say hi here!',
      phone: 'Phone Number',
      phonePlaceholder: 'Enter your phone number',
      searchPlaceholder: 'Search Country...',
      apiToken: 'API Token'
    },
    action: {
      signin: 'Sign in',
      signup: 'Sign up',
      confirmCode: 'Confirm code',
      signinWith: 'Sign in with',
      signupWith: 'Sign up with',
      close: 'Close',
      save: 'Save',
      cancel: 'Cancel',
      delete: 'Delete',
      edit: 'Edit',
      add: 'Add',
      remove: 'Remove',
      copy: 'Copy',
      copyToClipboard: 'Copy to clipboard',
      yes: 'Yes',
      no: 'No',
      ok: 'Ok',
      next: 'Next',
      back: 'Back',
      done: 'Done',
      continue: 'Continue'
    },
    instruction: {
      pleaseWait: 'Please, wait...',
      importing: 'Importing...',
      loading: 'Loading...',
      redirectFailed: 'Ooops, we couldn\'t redirect at this time. Please, try again in a few.',
      permission: 'It seems like you didn\'t give us the proper permission. Please, close this window and start the process again making sure you check the right permission.'
    },
    feedback: {
      copySuccess: 'Copied to the clipboard successfully!',
      copyError: 'Your browser does not support copy to clipboard.'
    },
    date: {
      now: 'now',
      past: '{0} ago',
      future: 'in {0}',
      secondOne: 'a second',
      secondOther: '{0} seconds',
      minuteOne: 'a minute',
      minuteOther: '{0} minutes',
      hourOne: 'an hour',
      hourOther: '{0} hours',
      dayOne: 'a day',
      dayOther: '{0} days',
      weekOne: 'a week',
      weekOther: '{0} weeks',
      monthOne: 'a month',
      monthOther: '{0} months',
      yearOne: 'a year',
      yearOther: '{0} years',
      hoursAgo: '{n} hours ago',
      hourAgo: '1 hour ago',
      minutesAgo: '{n} minutes ago',
      minuteAgo: '1 minute ago',
      justNow: 'just now',
      yesterday: 'yesterday',
      daysAgo: '{n} days ago',
      dayAgo: '1 day ago',
      monthsAgo: '{n} months ago',
      monthAgo: '1 month ago',
      yearsAgo: '{n} years ago',
      yearAgo: '1 year ago',
      second: 'second',
      seconds: 'seconds',
      minute: 'minute',
      minutes: 'minutes',
      hour: 'hour',
      hours: 'hours',
      day: 'day',
      days: 'days',
      week: 'week',
      weeks: 'weeks',
      month: 'month',
      months: 'months',
      year: 'year',
      years: 'years'
    },
    user: 'user | users',
    justYou: 'Just you',
    plans: {
      free: 'Free Plan',
      trial: 'Trial Plan',
      starter: 'Starter Plan',
      plus: 'Plus Plan',
      professional: 'Professional Plan'
    },
    roles: {
      owner: 'Owner',
      invited: 'Invited'
    }
  },
  auth: {
    acceptInvitation: {
      accepting: 'Accepting invitation...',
      error: {
        wasNotPossibleAccept: 'Ooops, it wasn\'t possible to accept the invitation at this time. Please, try again in a few.',
        checkTheLink: 'Invitation not found. Please, check the link and try again.',
        invitationExpired: 'Invitation expired. Please, request a new one.',
        invitationAlreadyAccepted: 'Invitation already accepted. Please, sign in.'
      }
    },
    signin: {
      title: 'Good to see you 👋',
      signup: 'Sign up',
      footer: 'Don\'t have an Yay! Forms account?',
      forgotPassword: 'Forgot your password?'
    },
    signup: {
      title: 'Create your account 🫶',
      footer: 'Already have an Yay! Forms account?'
    },
    confirmation: {
      title: 'Confirm your identity 🪪',
      instructions: 'You may have received a code in your email (see <b>spam</b> folder too). Please, enter the code below to finish.',
      footer: 'Didn\'t receive the code?',
      sendAnotherCode: 'Send another code',
      resendCodeFeedback: 'We sent you another code. Please, allow some seconds and check your spam folder.'
    },
    forgotPassword: {
      title: 'Recover your password 🔑',
      sendResetInstructions: 'Send reset instructions',
      sendResetFeedback: 'Great! Please, see recover instructions sent to your email.',
      footer: 'Go to <a href="/auth/signin" class="font-bold text-link">sign in</a> page'
    },
    createPassword: {
      title: 'Create your password 🔑',
      sendCreateInstructions: 'Send create instructions',
      sendCreateFeedback: 'Great! Please, see create password instructions sent to your email.',
      footer: 'Go to <a href="/auth/signin" class="font-bold text-link">sign in</a> page'
    },
    resetPassword: {
      title: 'Set your new password',
      saveNewPassword: 'Save new password',
      passwordUpdatedFeedback: 'Password updated successfully!'
    }
  },
  billing: {
    cancel: {
      redirectingToDashBoard: 'Redirecting to dashboard...'
    },
    checkout: {
      redirectingToCheckout: 'Redirecting to checkout...'
    },
    paymentMethods: {
      redirectingToPaymentMethods: 'Redirecting to payment methods...'
    },
    portal: {
      redirectingToBillingPortal: 'Redirecting to billing portal...'
    },
    pricing: {
      title: 'Select your new plan',
      yourPlan: 'Your current plan',
      per: 'per',
      pay: 'Pay',
      monthly: 'Monthly',
      yearly: 'Yearly',
      month: 'Month',
      year: 'Year',
      freeTrial: 'Start free trial',
      error: {
        pricingInformation: 'Ooops, we couldn\'t load the pricing information at this time. Please, try again in a few.',
        affiliateInformation: 'Ooops, we couldn\'t load the affiliate information at this time. Please, try again in a few.'
      }
    },
    plans: {
      singleProfessional: '<b>20k</b> Responses/monthly',
      singlePlus: '<b>2k</b> Responses/monthly',
      singleStarter: '<b>200</b> Responses/monthly',
      singleFree: '<b>20</b> Responses/monthly',
      unlimited: 'Unlimited forms',
      customEndingScreens: 'Custom ending screens',
      automaticKeyword: 'Automatic keyword extraction',
      sentimentDetection: 'Sentiment detection',
      emotionDetection: 'Emotion detection'
    },
    success: {
      title: 'Your subscription has been activated successfully. Redirecting to dashboard...'
    }
  },
  forms: {
    index: {
      edit: 'To edit this form, please open it in a bigger screen.'
    },
    share: {
      isNotPublishedYet: 'This form is not published yet. It will be visible to visitors as soon as you publish it.',
      tooltipPublic: 'Save changes and turn this form public',
      publish: 'Publish',
      share: 'Share your form',
      link: 'This is the link to your form',
      tooltipCopy: 'Copy to clipboard',
      copy: 'Copy',
      tooltipQrCode: 'Generate QR Code',
      preview: 'Preview',
      tooltipPreview: 'Preview your form',
      embed: 'Embed in your website',
      userTracking: 'User Tracking',
      exampleWithParams: 'Example with parameters:',
      changeThisValue: 'CHANGE_THIS_VALUE',
      embedCard: {
        wordpress: 'Download the Yay! Forms plugin for Wordpress.',
        standard: 'Exhibit your form as an integral part of your webpage.',
        fullPage: 'Display a full-page form as soon as your site loads.',
        popup: 'Your form appears in the middle of the screen.<br>Configure custom launch options, such as a time delay.',
        slider: 'Your form will slide in from the side (left or right).<br>Set custom launch settings or open with a button.',
        popover: 'Open as a floating popover when someone presses the button, or configure custom launch parameters.',
        sideTab: 'Open as a floating panel when someone presses the button, or configure custom launch parameters.'
      },
      notification: {
        success: 'URL copied to the clipboard!',
        error: 'Your browser does not support copy to clipboard.'
      }
    },
    integrate: {
      error: {
        loadIntegrations: 'Ooops, we couldn\'t load the integrations at this time. Please, try again in a few.',
        empty: 'To manage your integrations, please open it in a bigger screen.',
        loadWebhook: 'Ooops, we couldn\'t load the webhooks at this time. Please, try again in a few.'
      },
      infoUrl: 'Inform a URL that will receive data every time someone submit a form.',
      addWebhook: 'Add webhook...',
      showPayload: 'Show payload sample',
      payload: 'Payload Sample',
      payloadV1Description: 'Original format where answers are sent as a list.',
      payloadV2Description: 'New format where answers are organized by their Field ID, making it easier to find specific answers.'
    },
    results: {
      inbox: {
        hasNoFields: 'Here you can get the results of your responses as soon as you create fields and receive responses.',
        noResponsesFound: 'No responses found.',
        deleteError: 'It was not possible to delete the response',
        retrievingError: 'It was not possible to retrieve the responses at this time. Please, try again in a few minutes.',
        showOnlySubmitted: 'Show only complete responses',
        download: 'Download',
        downloadResponse: 'Download selected answer'
      },
      index: {
        switchContent: 'Show/Hide details about the answers every question received.',
        switchShow: 'Show details',
        devices: {
          allDevices: 'All devices',
          desktop: 'Desktop',
          mobile: 'Mobile',
          tablet: 'Tablet',
          other: 'Other'
        },
        times: {
          allTime: 'All time',
          today: 'Today',
          thisWeek: 'This week',
          thisMonth: 'This month',
          thisYear: 'This year'
        },
        statsSchema: {
          views: 'Views',
          starts: 'Starts',
          responses: 'Responses',
          completionRate: 'Completion Rate',
          timeToComplete: 'Time to complete'
        }
      },
      insights: {
        questions: 'Questions',
        insights: 'Insights',
        fieldAnswerOk: 'Our AI is thrilled to analyze these responses for you. Meanwhile, you can focus on things that really matter - like mastering the art of napping with your eyes open. 😴😅',
        fieldAnswerLeastFive: 'You will be able to get AI-generated insights as soon as this form receives at least 5 responses.',
        error: {
          loadThisTime: 'Ooops, we couldn\'t load the insights at this time. Please try again in a few.',
          empty: 'Here you will be able to get insights from your responses as soon as you create fields and receive answers.',
          loadThisForm: 'It was not possible to load the insights for this form. Please try again later.'
        },
        notification: {
          success: 'Insight created successfully!',
          error: 'Ooops, an error occurred. Please try again in a few.'
        }
      },
      responses: {
        download: 'Download',
        waiting: 'Reading responses...',
        tooltipDownload: 'Download the responses in the current page...',
        itemsPerPage: 'Items per page'
      }
    }
  },
  workspace: {
    createForm: 'Create form',
    successfully: ' created successfully!',
    form: 'Form '
  },
  me: {
    billing: 'Billing',
    plan: 'Plan:',
    payment: 'Payment:',
    currency: 'Currency:',
    cycle: 'Used in this cycle',
    languageTitle: 'Language',
    languageMessage: 'Here you can change the platform language.',
    languageLabel: 'Select language',
    integrations: 'Integrations',
    apiTokenMessage: 'Use the following API Token to allow integration access to your account',
    copy: 'Copy',
    loginLink: 'Login Link',
    tooltipCopy: 'Copy to clipboard',
    revokeApiToken: 'Revoke API Token',
    someIntegrations: 'Some of our integrations require an API Token to have access to your account.',
    ifYouWant: 'If you want to give access to such service, use the button bellow to generate an API Token.',
    generateApiToken: 'Generate API Token',
    supportAccess: 'Support Access',
    youAreCurrently: 'You are currently ',
    allowing: 'allowing ',
    ourSupport: 'our support team to have access to your account.',
    clickTheButton: 'Click the button below to revoke this access.',
    not: 'not ',
    enableSupportAccess: 'Enable Support Access',
    revokeSupportAccess: 'Revoke Support Access',
    errorState: 'Ooops, we couldn\'t load the user profile information at this time. Please, try again in a few.',
    notification: {
      copySuccess: 'Copied to the clipboard successfully!',
      copyError: 'Your browser does not support copy to clipboard.',
      apiTokenGeneratedSuccess: 'API Token generated successfully!',
      apiTokenGeneratedError: 'It was not possible to generate your api token at this time. Please try again.',
      apiTokenRevokedSuccess: 'API Token revoked!',
      apiTokenRevokedError: 'It was not possible to revoke your api token at this time. Please try again.',
      supportAccessGrantedSuccess: 'Support Access granted successfully!',
      supportAccessGrantedError: 'It was not possible to allows support access at this time. Please try again.',
      supportAccessRevokedSuccess: 'Support Access revoked!',
      supportAccessRevokedError: 'It was not possible to denies support access at this time. Please try again.',
      channelsUpdated: 'Notification channels updated successfully!',
      channelsUpdateError: 'Error updating notification channels. Please try again.'
    },
    notificationTitle: 'Notifications',
    notificationChannels: {
      title: 'Notification Channels',
      mail: {
        title: 'Email Notifications',
        subtitle: 'Receive important notifications via e-mail.'
      },
      whatsapp: {
        title: 'WhatsApp Notifications',
        subtitle: 'Receive important notifications via WhatsApp.'
      }
    },
    addOns: {
      title: 'Add-ons',
      additionalResponses: {
        title: 'Additional responses',
        subtitle: 'Continue receiving responses when reaching your plan’s limit.',
        description: 'If enabled, the exceeding responses will be automatically charged <a href="https://yayforms.com/pricing#pricing" class="text-link" target="_blank">according to your plan</a>.',
        additionalResponsesEnabled: 'Additional responses enabled!',
        additionalResponsesDisabled: 'Additional responses disabled!',
        error: 'It was not possible to update this Add-on at this time. Please try again later.'
      }
    },
    dangerZone: {
      title: 'Danger Zone',
      subtitle: 'Here you can cancel your account and delete all your data.',
      cancelAccount: 'Cancel Account'
    },
    userData: {
      title: 'User Data',
      nameLabel: 'Name',
      namePlaceholder: 'Enter your name',
      companyLabel: 'Organization Name',
      companyPlaceholder: 'Enter your organization name',
      emailLabel: 'Email',
      emailPlaceholder: 'Enter your email',
      phoneLabel: 'Phone',
      phonePlaceholder: 'Enter your phone number',
      updateSuccess: 'User data updated successfully',
      updateError: 'Error updating user data'
    },
    companyTitle: 'Organization',
    companyLabel: 'Organization Name',
    companyPlaceholder: 'Enter your organization name',
    companyUpdated: 'Organization name updated successfully',
    errorUpdatingCompany: 'Error updating organization name',
    save: 'Save',
    password: {
      title: 'Reset Password',
      description: 'You will receive an email with instructions to reset your password.',
      resetButton: 'Send Reset Email',
      resetSuccess: 'Email sent successfully!',
      resetError: 'Error sending email. Please try again.'
    },
    modalConfirmPassword: {
      title: 'Confirm Password',
      description: 'To change your email address, please confirm your current password.',
      password: 'Password',
      cancel: 'Cancel',
      confirm: 'Confirm',
      invalidPassword: 'Invalid password. Please try again.'
    }
  },
  layouts: {
    error: {
      title: 'Whoops! 😬',
      message: 'We know, we know, we messed up! You can contact us at ',
      and: 'and we can help out!',
      home: 'Home page'
    }
  },
  components: {
    account: {
      ModalAffiliate: {
        affiliate: 'Affiliate',
        sharingLink: 'Earn 20% to 30% recurring revenue by sharing your referral link with your audience, friends, clients, etc.',
        copy: 'Copy',
        copyToClipboard: 'Copy to clipboard',
        error: 'Ooops, we couldn\'t load the affiliate information at this time. Please, try again in a few.',
        notification: {
          success: 'URL copied to the clipboard!',
          error: 'Your browser does not support copy to clipboard.'
        }
      },
      modalPlans: {
        selectNewPlan: 'Select your new plan',
        per: 'per ',
        pay: 'Pay:',
        monthly: 'Monthly',
        yearly: 'Yearly',
        select: 'Select',
        yourCurrentPlan: 'Your current plan',
        error: 'Ooops, we couldn\'t load the affiliate information at this time. Please, try again in a few.',
        plans: {
          singleProfessional: '<b>20k</b> Responses/monthly',
          singlePlus: '<b>2k</b> Responses/monthly',
          singleStarter: '<b>200</b> Responses/monthly',
          singleFree: '<b>20</b> Responses/monthly',
          unlimited: 'Unlimited forms',
          customEndingScreens: 'Custom ending screens',
          automaticKeyword: 'Automatic keyword extraction',
          sentimentDetection: 'Sentiment detection',
          emotionDetection: 'Emotion detection'
        }
      }
    },
    auth: {
      theSocialButtons: {
        or: 'OR'
      }
    },
    common: {
      oneTimePassword: {
        wait: 'Wait until the user enters the required number of characters',
        invalid: 'Invalid pasted data'
      },
      alertDialog: {
        ok: 'Ok, got it!'
      },
      closeButton: {
        close: 'Close'
      },
      confirmationDialog: {
        wait: 'Wait a minute!',
        danger: 'danger',
        no: 'No',
        yes: 'Yes'
      },
      errorNotification: {
        default: 'Unable to perform the operation.',
        close: 'Close'
      },
      inlineEditor: {
        tooltip: 'Click to edit'
      },
      stateBlock: {
        default: 'Ooops, an error occurred. Please try again in a few.'
      },
      successNotification: {
        default: 'Operation performed successfully!'
      },
      UndoNotification: {
        default: 'Action performed successfully!',
        close: 'Close'
      },
      UpgradeBadge: {
        available: 'This feature is available on the following plans:',
        pleaseUpgrade: '.<br> Please, upgrade your plan to use it.',
        upgradeYourPlan: 'Upgrade your plan to use this feature.'
      },
      userAvatar: {
        owner: 'owner'
      },
      userMiniProfile: {
        remove: 'Remove',
        fromWorkspace: 'from workspace...',
        revokeAccess: 'Revoke access to this workspace'
      }
    },
    formEdit: {
      fieldTypes: {
        calendly: {
          invalid: 'The calendar will appear as soon as a valid Calendly URL is provided.',
          enablePrefill: 'Enable prefill',
          name: 'Name',
          email: 'Email',
          customField: 'Custom Field',
          deleteCustomField: 'Delete field',
          deleteFieldTooltip: 'Delete prefill custom field',
          addFieldTooltip: 'Add prefill for custom field',
          nameField: 'Name field...',
          emailField: 'Email field...',
          customFieldLabel: 'Custom field...'
        },
        date: {
          PreviewOnly: 'Preview only 👀',
          day: 'Day',
          month: 'Month',
          year: 'Year',
          dateFormat: 'Date format'
        },
        dropdown: {
          selectAnOption: 'Select an option...',
          choices: 'Choices',
          addChoice: 'Add choice',
          editChoices: 'Edit choices',
          oneItem: 'One item per line',
          onlyUniqueItems: 'Please remove duplicated items.',
          minCount: 'At least 2 choices must be entered.',
          cancel: 'Cancel',
          notificationSuccess: 'Choices saved!',
          title: 'Hands up!',
          messageFollowingChoices: 'The following choices are used in logic rules.',
          messageWeAdvice: 'If you proceed, we advice you to review your logic rules since it will be broken.<br>Are you sure you want to save the new choices?',
          noButtonText: 'Cancel',
          yesButtonText: 'Save choices',
          randomized: 'Randomized',
          alphabetically: 'Alphabetical order'
        },
        email: {
          previewOnly: 'Preview only 👀'
        },
        endingScreen: {
          thisEnding: 'This ending screen will not be shown, instead the user will be redirected to:',
          sayBye: 'Say bye here.',
          callToAction: 'Call to action',
          buttonText: 'Button text',
          buttonLink: 'Button link',
          tooltip: 'Click to edit ✍️. <br> Type <b>@</b> to insert a variable.',
          reload: 'Reload',
          help: 'Auto redirects the user to another page on completion.',
          redirectTo: 'Redirect to'
        },
        longText: {
          previewOnly: 'Preview only 👀',
          typeYourAnswer: 'Type your answer here...',
          toMakeLineBreak: 'To make a line break',
          keywordExtraction: 'Keyword extraction',
          textAnalysis: 'Text analysis',
          textClassification: 'Text classification',
          enableKeywordExtraction: 'Enables automatic keyword<br> extraction from answers.',
          enableTextAnalysis: 'Automatically detect the emotion <br>(love, joy, surprise, sadness, anger, and fear)<br> and sentiment (👍 👎) from answers.',
          enableTextClassification: 'Automatically classifies responses into predefined categories.<br>This feature is free while in BETA.',
          maxCharacters: 'Max characters',
          categories: {
            label: 'Categories',
            placeholder: 'Add one category per line',
            help: 'Add up to 10 categories to automatically sort the answers.'
          },
          autoUpdateCategories: 'Auto create categories',
          enableAutoUpdateCategoriesHelp: 'Automatically create categories based on the answers received.'
        },
        multipleChoice: {
          chooseMany: 'Choose as many as you like',
          option: 'Option...',
          removeChoice: 'Remove choice',
          other: 'Other',
          addChoice: 'Add choice',
          error: 'It seems like the option you are trying to add already exists. Please rename the current one to add another.',
          confirmDeletion: 'This option is used in logic rules.<br>If you proceed, you will break the logic.<br><br>Are you sure you want to delete it?',
          multipleSelection: 'Multiple selection',
          randomized: 'Randomized',
          unlimited: 'Unlimited',
          exactNumber: 'Exact Number',
          range: 'Range'
        },
        pictureChoice: {
          multipleSelection: 'Multiple selection',
          randomized: 'Randomized',
          superSize: 'Super size'
        },
        number: {
          previewOnly: 'Preview only 👀',
          typeYourAnswer: 'Type your answer here...',
          decimal: 'Decimal',
          decimalPlaces: 'Decimal places',
          decimalSeparator: 'Decimal separator',
          prefix: 'Prefix',
          suffix: 'Suffix'
        },
        opinionScale: {
          previewOnly: 'Preview only 👀',
          fromLabel: 'From label',
          toLabel: 'To label',
          dontLike: 'E.g. don\'t like it at all',
          loveIt: 'E.g. love it!',
          from: 'From',
          to: 'To'
        },
        phone: {
          previewOnly: 'Preview only 👀',
          defaultCountryCode: 'Default country code'
        },
        rating: {
          previewOnly: 'Preview only 👀',
          items: 'Items',
          icon: 'Icon',
          star: 'Star',
          heart: 'Heart',
          user: 'User',
          up: 'Thumbs up',
          tick: 'Tick',
          crown: 'Crown',
          trophy: 'Trophy'
        },
        shortText: {
          previewOnly: 'Preview only 👀',
          typeYourAnswer: 'Type your answer here...',
          enablesAutomaticKeyword: 'Enables automatic keyword<br> extraction from answers.',
          automaticallyDetectEmotion: 'Automatically detect the emotion <br>(love, joy, surprise, sadness, anger, and fear)<br> and sentiment (👍 👎) from answers.',
          keywordExtraction: 'Keyword extraction',
          textAnalysis: 'Text analysis',
          textClassification: 'Text classification',
          enableKeywordExtraction: 'Enables automatic keyword<br> extraction from answers.',
          enableTextAnalysis: 'Automatically detect the emotion <br>(love, joy, surprise, sadness, anger, and fear)<br> and sentiment (👍 👎) from answers.',
          enableTextClassification: 'Automatically classifies responses into predefined categories.<br>This feature is free while in BETA.',
          maxCharacters: 'Max characters',
          fieldMask: 'Field mask',
          maskTooltip: '<b>#</b> : Any number<br><b>@</b> : Any letter<br><b>*</b> : Letter or number',
          maskHelp: '<b>#</b> represents any number, <b>@</b> any letter, and <b>*</b> can be either a letter or a number. For more details, visit <a href="https://help.yayforms.com/article/227-como-adicionar-uma-mascara-no-seu-campo-de-texto-curto" target="_blank"><b>this complete guide</b></a>.',
          maskPlaceholder: '###-##-####',
          categories: {
            label: 'Categories',
            placeholder: 'Add one category per line',
            help: 'Add up to 10 categories to automatically sort the answers.'
          },
          autoUpdateCategories: 'Auto create categories',
          enableAutoUpdateCategoriesHelp: 'Automatically create categories based on the answers received.'
        },
        statement: {
          sayHiHere: 'Say hi here!',
          continue: 'Continue',
          hideButton: 'Hide button',
          button: 'Button'
        },
        website: {
          previewOnly: 'Preview only 👀'
        },
        welcomeScreen: {
          start: 'Start',
          button: 'Button'
        },
        yesNo: {
          y: 'Y',
          n: 'N',
          yes: 'Yes',
          no: 'No'
        }
      },
      fieldHeader: {
        clickToEdit: 'Click to edit ✍️. <br>',
        toInsert: 'to insert a variable.',
        atSign: 'Type <b>@</b> ',
        placeholder: 'Description (optional)',
        titlePlaceholder: 'Your question here.',
        align: 'left'
      },
      fieldListItem: {
        duplicate: 'Duplicate',
        delete: 'Delete...',
        dupDel: 'Duplicate, delete...'
      },
      fieldRequired: {
        required: 'Required'
      },
      fieldSettingSwitch: {
        upgradeYourPlan: 'Upgrade your plan to use this feature.'
      },
      formVariable: {
        clickToEdit: 'Click to edit'
      },
      LogicActionEditor: {
        clickToChoose: 'click to choose another one'
      },
      logicConditionEditor: {
        clickToChoose: 'Click to choose a field',
        variables: 'Variables',
        fields: 'Fields',
        addNewCondition: 'Add new condition',
        condition: '+ condition',
        delete: 'Delete',
        deleteThisCondition: 'Delete this condition',
        if: 'IF'
      },
      logicLogicalOperator: {
        or: 'or',
        and: 'and'
      },
      logicRuleEditor: {
        then: 'Then',
        deleteThisRule: 'Delete this rule',
        delete: 'Delete',
        deleteAction: 'Delete Action',
        deleteThisAction: 'Delete this Action',
        addAction: 'Add action'
      },
      logicRuleItem: {
        if: 'IF',
        clickToEdit: 'Click to edit this rule'
      },
      mediaRenderer: {
        remove: 'Remove'
      },
      modalAddMedia: {
        addImage: 'Add image or video',
        selectImageToUpload: 'Select an image up to 2MB.',
        allowedExtensions: 'Allowed extensions: svg, jpeg, png, jpg, gif.',
        optimizingTheFile: 'Optimizing the file for the web.',
        pleaseWait: 'Please wait...',
        egImage: 'E.g. https://yourcompany.com/logo.png',
        enterUrlImage: 'Enter the URL of the image you want to include in your form.',
        egYoutube: 'E.g. https://www.youtube.com/watch?v=L_LUpnjgPso',
        enterValidImageUrl: 'Enter a valid image URL.',
        enterUrlVideo: 'Enter the URL of the video from Youtube, Vimeo or Panda Video.',
        enterValidYoutube: 'Enter a valid Youtube,Vimeo URL or Panda Video.',
        cancel: 'Cancel',
        add: 'Add',
        imageUpload: 'Image upload',
        imageUrl: 'Image URL',
        gallery: 'Gallery',
        areYouSure: 'Are you sure you want to delete this photo from your gallery?',
        errorMedia: 'The image must be of type: png, jpg, jpeg, gif, with a maximum size of 2MB.',
        errorUpload: 'Sorry, the upload could not be completed',
        errorDelete: 'Sorry, could not delete media',
        errorFind: 'Sorry, it was not possible to search for the media',
        emptyGallery: 'Your images will appear here as soon as you upload them.'
      },
      modalEditVariables: {
        variables: 'Variables',
        variablesAllow: 'Variables allow you to make custom calculations over logic rules based on your forms responses.',
        defaultVariables: 'Default variables',
        customVariables: 'Custom variables',
        matches: 'Only letters, numbers and underscores are allowed',
        unique: 'This variable name is already in use',
        removeVariable: 'Remove variable',
        addVariable: 'Add variable',
        close: 'Close',
        confirmDeletionLogic: 'This variable is used in logic rules.<br><br>Are you sure you want to delete this variable and the logic rules using it?',
        confirmDeletionContent: 'This variable is being used on a question\'s content.<br><br>Do you want to delete this variable and remove all its occurrences from questions that are using it?',
        message: 'There are errors in the form.<br>Close it any way?',
        variant: 'primary',
        noButtonText: 'No, let me fix it',
        yesButtonText: 'Yes, close without saving'
      },
      richInputSuggestion: {
        variables: 'Variables',
        notFoundVariables: 'No variables found.'
      },
      theFieldLayout: {
        imageVideo: 'Image / Video',
        add: '+ Add',
        remove: 'Remove',
        onTheTop: 'On the top',
        onTheMiddle: 'On the middle',
        height: 'Height',
        width: 'Width',
        auto: 'auto',
        altText: 'Alt Text',
        imageDescription: 'Image description'
      },
      theFieldList: {
        welcomeScreen: 'Welcome screen',
        addWelcomeScreen: 'Add welcome screen',
        questions: 'Questions',
        AddQuestion: 'Add question',
        endingScreens: 'Ending screens',
        AddEndingScreens: 'Add ending screen',
        confirmDeletionLogic: 'This question has logic rules.<br>If you proceed, you will break the logic.<br><br>Are you sure you want to delete it?',
        confirmDeletionOtherLogic: 'This question is used in the logic rules of other questions. If you proceed, you will break the logic.<br><br>Are you sure you want to delete it?',
        confirmResponsesLossEnding: 'If you proceed, you will lose the number of views and drop-off associated to it. Are you sure you want to delete it?',
        confirmResponsesLossWelcome: 'This question already has responses. If you proceed, you will lose all of its responses. We recommend you download the answers before proceeding. Are you sure you want to delete this field and all its answers?'
      },
      theFieldPreview: {
        chooseOrAdd: 'Choose or add a new question on the left panel.'
      },
      theFieldSettings: {
        welcome: 'Welcome and ending screen cannot be changed.',
        type: 'Type',
        settings: 'Settings',
        select: 'Select a question to show its settings.',
        alert: 'This question already has responses, so you can\'t change its type at this time. Instead, we recommend you download the answers, delete the field, and add a new one to the kind you need.'
      },
      theFormLeftMenu: {
        content: 'Content',
        design: 'Design',
        settings: 'Settings'
      },
      theFormRightMenu: {
        question: 'Question',
        logic: 'Logic'
      },
      theFormSettings: {
        formSettings: 'Form settings',
        language: 'Language',
        theLanguageToShow: 'The language to show the buttons, hints, and error messages of the form.',
        integrationsSettings: 'Integrations settings',
        hidePowered: 'Hide Powered by Yay! Forms',
        removeTheCredits: 'Remove the credits shown in the footer of your forms.',
        closeForm: 'Close form to new responses',
        sendIncomplete: 'Send incomplete responses',
        enableSending: 'Enable sending of incomplete responses to the integrated platform.<br>If this setting is turned on, you must specify a delay (in minutes).<br>This delay denotes the wait time before the incomplete response is sent.',
        waitingTime: 'Waiting time in minutes',
        metaTagsSettings: 'Meta Tags Settings',
        title: 'Title',
        enterTitle: 'Enter the title',
        description: 'Description',
        enterDescription: 'Enter the description',
        previewImage: 'Preview Image',
        aiFeedback: 'Enable AI Feedback',
        aiFeedbackHelp: 'Activate AI feedback to provide personalized feedbacks based on user answers.',
        prompt: {
          label: 'AI Prompt',
          placeholder: 'E.g. If the user\'s score is below 5, tell them how to improve it based on their answers.',
          help: 'Set up a prompt to generate personalized feedback on answers with artificial intelligence. <a href="https://help.yayforms.com/article/182-guide-to-placeholders-for-email-and-messages-customization" target="_blank"><b><u>Learn more</u></b></a>.'
        }
      },
      theFormVariables: {
        variables: 'Variables',
        removeVariable: 'Remove variable',
        addVariable: 'Add variable',
        selectAQuestion: 'Select a question to show its variables.'
      },
      theLogicRuleList: {
        variables: 'Variables',
        createVariables: 'Create variables to use in your logic rules.',
        pleaseSelect: 'Please select a question on the left panel to add a new logic rule since it can\'t be created for ending screens.',
        rules: 'Rules',
        theFollowingRules: 'The following rules will be applied when someone answers the question',
        else: 'ELSE',
        addRule: 'Add rule',
        byDefault: 'By default',
        otherwise: 'Otherwise',
        goTo: 'Go to',
        selectAQuestion: 'Select a question to show its logic rules.'
      },
      themeListItem: {
        clickToSelect: 'Click to select this theme.',
        question: 'Question',
        answer: 'Answer',
        thisTheme: 'This theme is already in use for this form.',
        selectThisTheme: 'Select this theme.',
        editFont: 'Edit font and colors of this theme.',
        duplicate: 'Duplicate this theme',
        thisThemeIsBeing: 'This theme is being used by this form. Please, select other theme if you want to delete this one.',
        deleteThisTheme: 'Delete this theme',
        cannotEditDefaultTheme: 'Cannot edit the default theme.',
        cannotDeleteDefaultTheme: 'Cannot delete the default theme.'
      },
      theThemeEditor: {
        goBack: 'Go back',
        themeName: 'Theme\'s name',
        font: 'Font name',
        fontSize: 'Font size',
        buttonBorderRadius: 'Corners',
        page: 'Page',
        question: 'Question',
        button: 'button',
        revert: 'Revert',
        save: 'Save',
        sm: 'SM',
        md: 'MD',
        lg: 'LG',
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
        title: 'Discard changes?',
        message: 'There are unsaved changes in this theme. Are you sure you want to discard them?',
        noButtonText: 'Cancel',
        yesButtonText: 'Yes, discard changes',
        discardChangesTooltip: 'Discard changes',
        saveChangesTooltip: 'Save changes to theme'
      },
      theThemeList: {
        themes: 'Themes',
        newTheme: 'New theme',
        confirmDeletion: 'Are you sure you want to delete the theme',
        myThemes: 'My Themes',
        gallery: 'Gallery'
      }
    },
    formIntegrate: {
      logs: {
        title: 'Integration Logs',
        columnNameIntegration: 'Integration Name',
        columnType: 'Integration Type',
        columnTrigger: 'Trigger',
        columnStatus: 'Status',
        columnCreatedAt: 'Created At',
        columnActions: 'Actions',
        retry: 'Retry',
        retrySuccess: 'Integration retried successfully!',
        retryError: 'Failed to retry integration.',
        errorFetchingLogs: 'Failed to fetch integration logs.',
        refresh: 'Refresh',
        loading: 'Loading logs...',
        emptyState: 'No logs available to display',
        modalTitle: 'Log Details',
        details: 'Details',
        msgBox: {
          title: 'Wait a minute!',
          confirmRetry: 'Are you sure you want to retry this integration?',
          yesButtonText: 'Yes',
          noButtonText: 'No'
        },
        loadingMessage: 'Loading log details...',
        noRowsTitle: 'No logs available',
        noRowsDescription: 'There are no logs available to display.',
        logNotFound: 'Log not found.',
        status: {
          succeeded: 'Succeeded',
          failed: 'Failed',
          voided: 'Voided'
        }
      },
      credentialsDropdown: {
        connect: 'Connect {providerName} account',
        account: ' account',
        loading: 'Loading ',
        accounts: ' accounts',
        connectOther: 'Connect other ',
        authorize: 'Authorize Yay! Forms'
      },
      integrationCard: {
        active: 'Active',
        inactive: 'Inactive',
        tooltip: 'Activate or deactivate this integration',
        by: 'By',
        or: 'or',
        enabledTip: 'This integration is enabled',
        disabledTip: 'This integration has been disabled due to an error or by the form owner\'s decision.<br>Please edit the integration settings and save again to enable the integration',
        configure: 'Configure',
        settings: 'Settings',
        test: 'Test',
        gettingStarted: 'Getting started',
        copyToClipboard: 'Copy to clipboard',
        copy: 'Copy',
        itSeemsLike: 'It seems like you don\'t have an API Token yet.',
        generate: 'Generate API Token',
        see: 'See ',
        guide: '\'s guide',
        open: 'Open',
        yourCurrentPlan: 'Your current plan ',
        doesntAllow: ' doesn\'t allow you to integrate with ',
        pleaseUpgrade: ' Please upgrade your plan to proceed.',
        integration: ' Integration',
        notification: {
          apiTokenCopiedSuccess: 'API Token copied to the clipboard!',
          apiTokenCopiedError: 'Your browser does not support copy to clipboard.',
          apiTokenGeneratedSuccess: 'API Token generated successfully!',
          apiTokenGeneratedError: 'It was not possible to generate your api token at this time. Please try again.',
          testSuccess: 'Test sent successfully!',
          testError: 'It was not possible to send the test at this time. Please try again later.',
          retrySuccess: 'Integration retried successfully!',
          retryError: 'Failed to retry integration.'
        }
      },
      modalAddWebhook: {
        title: 'New webhook',
        name: 'Name',
        egSendResponse: 'E.g. Send response to CRM',
        aNameToHelpYouRemember: 'A name to help you remember what this webhook does.',
        endpoint: 'Endpoint',
        sendDataTo: 'We\'ll send data to this URL.',
        yourWebsite: 'https://yourwebsite.com/webhook',
        labelVersion: 'Response Format Version',
        helpVersion: 'Select the version format for the webhook response. In version V2, answers are organized by the corresponding Question ID.',
        labelSecret: 'Secret',
        placeholderSecret: 'My secret...',
        helpSecret: 'If specified, will be used to sign the webhook payload with HMAC SHA256, so that you can verify that it came from Yay! Forms.',
        labelSsl: 'SSL Verification',
        helpSsl: 'Check if you want Yay! Forms to verify SSL certificates when delivering payloads.',
        labelPastIntegrations: 'Execute on Past Responses',
        helpPastIntegrations: 'Check if you want to execute this integration on all past responses.',
        LabelHeader: 'Custom Header',
        placeholderHeaderName: 'Header name...',
        placeholderHeaderValue: 'Header value...',
        helpHeaderName: 'If specified, will add a custom header to the webhook payload.',
        labelTriggerCondition: 'Trigger Condition (optional)',
        placeholderTriggerCondition: 'E.g. {{field_id}} CONTAINS "yes" AND {{field_id}} >= "25000"',
        helpTriggerCondition: 'The formula that specifies when to trigger the Webhook. Leave it blank to always trigger when the form is submitted. <a href="https://help.yayforms.com/article/205-how-to-create-expressions-for-conditional-execution-of-integrations" target="_blank"><b><u>Learn more</u></b></a>.',
        labelCaseInsensitive: 'Case-insensitive trigger condition',
        helpCaseInsensitive: 'Check this box if you want to make the webhook trigger condition case insensitive. If checked, expressions such as "I Accept" or "I accept" will be considered the same.',
        cancel: 'Cancel',
        save: 'Save',
        webhook: 'Webhook saved successfully!'
      },
      modalConfigureOauthIntegration: {
        disconnect: 'Disconnect',
        cancel: 'Cancel',
        saveIntegration: 'Save integration',
        saving: 'Saving...',
        integration: '{integrationName} Integration',
        confirmDeletion: 'Are you sure you want to delete the',
        notification: {
          integrationSavedSuccess: ' integration saved successfully!',
          disconnectSuccess: ' disconnected successfully!'
        }
      },
      ModalConfigureWebhookIntegration: {
        disconnect: 'Disconnect',
        cancel: 'Cancel',
        saveIntegration: 'Save',
        confirmDeletion: 'Are you sure you want to delete the',
        integration: ' Integration',
        notification: {
          integrationSavedSuccess: ' integration saved successfully!',
          disconnectSuccess: ' disconnected successfully!'
        }
      },
      modalEditWebhook: {
        title: 'Edit webhook',
        LabelName: 'Name',
        placeholderName: 'E.g. Send response to CRM',
        helpName: 'A name to help you remember what this webhook does.',
        labelUrl: 'Endpoint',
        placeholderUrl: 'New endpoint...',
        helpUrl: 'We\'ll send data to this URL.',
        labelVersion: 'Response Format Version',
        helpVersion: 'Select the version format for the webhook response. In version V2, answers are organized by the corresponding Question ID.',
        labelSecret: 'Secret',
        placeholderSecret: 'My secret...',
        helpSecret: 'If specified, will be used to sign the webhook payload with HMAC SHA256, so that you can verify that it came from Yay! Forms.',
        labelSsl: 'SSL Verification',
        helpSsl: 'Check if you want Yay! Forms to verify SSL certificates when delivering payloads.',
        labelPastIntegrations: 'Execute on Past Responses',
        helpPastIntegrations: 'Check if you want to execute this integration on all past responses.',
        LabelHeader: 'Custom Header',
        placeholderHeaderName: 'Header name...',
        placeholderHeaderValue: 'Header value...',
        helpHeaderName: 'If specified, will add a custom header to the webhook payload.',
        labelTriggerCondition: 'Trigger Condition (optional)',
        placeholderTriggerCondition: 'E.g. {{field_id}} CONTAINS "yes" AND {{field_id}} >= "25000"',
        helpTriggerCondition: 'The formula that specifies when to trigger the Webhook. Leave it blank to always trigger when the form is submitted. <a href="https://help.yayforms.com/article/205-how-to-create-expressions-for-conditional-execution-of-integrations" target="_blank"><b><u>Learn more</u></b></a>.',
        labelCaseInsensitive: 'Case-insensitive trigger condition',
        helpCaseInsensitive: 'Check this box if you want to make the webhook trigger condition case insensitive. If checked, expressions such as "I Accept" or "I accept" will be considered the same.',
        cancel: 'Cancel',
        save: 'Save'
      },
      genericCredentialModal: {
        title: '{provider} Settings',
        cancel: 'Cancel',
        save: 'Save',
        success: 'Settings saved successfully!',
        testing: 'Testing...'
      },
      theIntegrationNavbar: {
        integrations: 'Integrations'
      },
      webhookListItem: {
        active: 'Active',
        inactive: 'Inactive',
        tooltip: 'Edit, delete, test...',
        edit: 'Edit',
        delete: 'Delete',
        test: 'Test',
        confirmDelete: 'Are you sure you want to delete this webhook?',
        enabledTip: 'This webhook is activated.',
        disabledTip: 'This webhook has been disabled due to an error or by decision of the form owner.<br>Please edit the webhook settings and save again to enable the integration.',
        switchTooltip: 'Activate or deactivate this webhook'
      }
    },
    formResults: {
      emotions: {
        love: 'love',
        joy: 'joy',
        surprise: 'surprise',
        sadness: 'sadness',
        anger: 'anger',
        fear: 'fear'
      },
      sentiments: {
        positive: 'positive',
        neutral: 'neutral',
        negative: 'negative'
      },
      answerBars: {
        showing: 'Showing ',
        answersFromSubmitted: ' answers from submitted forms out of',
        onlyAnswers: 'Only answers of submitted forms are shown here.',
        answers: 'answers'
      },
      answerList: {
        emotions: 'Emotions',
        sentiments: 'Sentiments',
        keywords: 'Keywords',
        textClassification: 'Text classification',
        messageKeywordsWill: 'The keywords will be shown here as soon as this form gets a minimum of responses.',
        messageTextClassification: 'The text classification will be shown here as soon as this form gets a minimum of responses.',
        copyKeywords: 'Copy keywords to clipboard',
        copyTextClassification: 'Copy text classification to clipboard',
        copy: 'Copy',
        occurrencesFor: ' occurrences for ',
        answers: 'Answers',
        messageNoAnswers: 'No answers were found with the current filter.',
        loading: 'Loading...',
        notificationSuccess: 'Keywords copied to the clipboard!',
        notificationError: 'Your browser does not support copy to clipboard.'
      },
      answerVerticalBars: {
        showing: 'Showing',
        answersFromSubmitted: 'answers from submitted forms out of',
        onlyAnswers: 'Only answers of submitted forms are shown here.',
        answers: 'Answers',
        npsTooltip: 'NPS = % Promoters - % Detractors'
      },
      emojiBadge: {
        value: 'value',
        answers: 'answers'
      },
      fieldSummary: {
        uniqueViews: 'Unique views',
        views: 'Views',
        itIncludes: 'It includes answers from both submitted and not submitted forms.',
        answers: 'Answers',
        peopleWho: 'People who dropped off answering the form in this question.',
        DropOff: 'Drop-off',
        resultsWillAppears: 'Results will appear here as soon as someone answers this form.',
        weCouldntLoad: 'Ooops, we couldn\'t load the answers at this time. Please, try again in a few.'
      },
      fileUploadCellRenderer: {
        view: 'View',
        alert: 'Failed to download file!'
      },
      InboxItem: {
        notAnswered: 'Not answered',
        deleteResponse: 'Delete response',
        createdAt: 'Created at:',
        submittedAt: 'Submitted at:'
      },
      MarkdownCellRenderer: {
        showLess: 'Show less',
        showMore: 'Show more'
      },
      modalGenerateInsight: {
        generateInsight: 'Generate insight',
        currently: 'Currently, we have a certain limit on how much data we can examine at once. Thus, please select the range from which you want to get insights, and we will do our best to analyze as many answers as possible in this range.',
        labelInterval: 'What interval would you like to analyze?',
        labelWhatKind: 'What kind of insight would you like to get?',
        TooltipSelect: 'Select a recipe to generate insights for',
        select: 'Select...',
        resultsPreview: 'Results Preview',
        inThisRange: 'In this range, we will analyze <strong>{analyze}</strong> answer out of <strong>{generate}</strong> to generate insights',
        usingRecipe: 'using the recipe',
        cancel: 'Cancel',
        theInsight: 'The insights creation has been started...'
      },
      textAnswerRender: {
        tooltipEmotion: 'We are still analyzing the emotion of this text. It\'ll be available soon.',
        tooltipSentiment: 'We are still analyzing the sentiment of this text. It\'ll be available soon.'
      },
      theFieldInsightsPanel: {
        generateNewInsight: 'Generate new insights...',
        generateInsight: 'Generate insights',
        generatingInsight: 'Generating insights...',
        generating: 'Generating...',
        endAt: 'Ends at:',
        insight: 'Insight',
        copyClipboard: 'Copy to clipboard',
        messageOurIa: 'Our AI is thrilled to analyze these responses for you. Meanwhile, you can focus on things that really matter - like mastering the art of napping with your eyes open. 😴😅',
        messageCouldntLoad: 'Ooops, we couldn\'t load the insights at this time. Please, try again in a few.',
        pleaseWait: 'Please wait until the insight is generated.',
        insightNotIf: 'Insight copied to the clipboard!',
        yourBrowserDoesNotSupport: 'Your browser does not support copy to clipboard.'
      },
      theInboxTable: {
        loading: 'Loading...',
        clickToChoose: 'Click to choose a field',
        fields: 'Fields',
        errorGetAnswers: 'It was not possible to fetch the responses'
      },
      theInsightFieldList: {
        questions: 'Questions'
      },
      theResponsesTable: {
        placeholder: 'Search on open-ended questions...',
        deleteSelected: 'Delete selected responses',
        loadingMessage: 'Loading responses, please wait...',
        title: 'No results to show yet.',
        description: 'The results will be shown here as soon as someone answers your form.',
        alert: 'At least one response must be selected to delete.',
        confirmDeletion: 'Are you sure you want to delete the ',
        selectedResponse: ' selected response(s)?',
        headerNameStarted: 'Started at',
        headerNameSubmitted: 'Submitted at',
        headerNameResponseStatus: 'Response status',
        responseStatusCompleted: 'Completed',
        responseStatusPartial: 'Partial',
        responseStatusTest: 'Test',
        nextPage: 'Next page',
        previousPage: 'Previous page'
      },
      theResultsNavbar: {
        getAnOverview: 'Get an overview of the performance and responses your form received.',
        summary: 'Summary',
        insightsAutomagically: 'Insights automagically generated for you.',
        insights: 'Insights',
        seeAllResponses: 'View all the responses in a table.',
        responses: 'Responses',
        seeInbox: 'View responses individually.',
        inbox: 'Inbox'
      }
    },
    formShare: {
      embedSettings: {
        embedMode: 'Embed mode',
        design: 'Design',
        hiddenFields: 'Hidden fields',
        utmParameters: 'UTM Parameters'
      },
      modalPreview: {
        desktop: 'Desktop',
        mobile: 'Mobile',
        reload: 'Reload'
      },
      modalEmbed: {
        customize: 'Customize and embed in your website',
        copyAndPaste: 'Copy and paste the code below on the body of your page in the section you want it to be shown.',
        copyToClipboard: 'Copy to clipboard',
        copyEmbedCode: 'Copy embed code',
        notificationSuccess: 'Code copied to the clipboard!',
        notificationError: 'Your browser does not support copy to clipboard.'
      },
      modalWordPress: {
        title: 'Install our WordPress plugin',
        step1: 'Download the Yay! Forms plugin for WordPress.',
        step2: '2. From your WordPress dashboard, choose Plugins > Add New.',
        step3: '3. Click Upload Plugin at the top of the page.',
        step4: '4. Click Choose File, locate the plugin.',
        step5: '5. After the installation is complete, click Activate Plugin.'
      },
      modalQrcode: {
        title: 'Save your QR Code',
        pointYourCamera: 'Point your camera to this QR code to open this form. You can download this QR code to promote your form in any media.',
        cancel: 'Cancel',
        downloadQrCode: 'Download QR Code'
      },
      theHiddenFields: {
        title: 'Hidden fields',
        addMyParameter: 'Add any parameter, such as an email or a custom id, to help you track who has completed the form. After adding a hidden field, it can be sent via query string as in the following example:',
        deleteHiddenField: 'Delete hidden field',
        addHiddenField: '+ Add hidden field...',
        matches: 'Only letters, numbers, dashes and underscores are allowed.',
        validateHiddenField: 'The name you assigned to the hidden field is already in use or needs to be enabled through proper integration.',
        cancel: 'Cancel'
      },
      theUtmParameters: {
        title: 'UTM Parameters',
        description: 'Add UTM parameters (utm_source, utm_medium, utm_campaign, utm_term, utm_content) to your form URL so you can track your users.'
      }
    },
    forms: {
      modalMoveForm: {
        title: 'Move form',
        save: 'Save',
        cancel: 'Cancel',
        success: 'Form moved successfully!',
        noWorkspacesAvailable: 'No workspaces available to move the form.'
      },
      formListItem: {
        duplicateDelete: 'Duplicate, delete...',
        duplicate: 'Duplicate',
        move: 'Mover',
        export: 'Export',
        delete: 'Delete...',
        noResponsesYet: 'No responses yet',
        live: 'live',
        scheduled: 'scheduled',
        creating: 'creating...',
        areYouSureYouWant: 'Are you sure you want to delete the form ',
        andAll: 'and all',
        responses: ' responses',
        notificationSuccess: 'URL copied to the clipboard!',
        notificationError: 'Your browser does not support copy to clipboard.',
        bySharing: 'By sharing the following URL with another Yay! Forms users, you allow them to import a copy of this form into their account: ',
        yourFormHasBeen: 'Your Form has been exported!',
        copyImport: 'Copy Import Link',
        brainstormingIdeas: 'Brainstorming ideas...',
        addingQuestions: 'Adding questions...',
        puttingFinishing: 'Putting finishing touches...'
      },
      modalNewForm: {
        createForm: 'Create a form...',
        tooltipIa: 'Just tell us the purpose of your form and let Artificial Intelligence do the hard work for you.',
        automagically: 'Automagically by AI',
        tooltipCreateForm: 'Create a form with your bear hands from scratch.',
        fromScratch: 'From scratch',
        whatIsThe: 'What is the',
        specific: 'specific',
        purposeOfThisForm: 'purpose of this form?',
        placeholder: 'Write what do you want to accomplish with this form.',
        pleaseDescribe: 'Please, describe it in the language of your users.',
        createFromWith: 'Create form with AI assist',
        untitledForm: 'Untitled form',
        notificationSuccess: 'We are creating your form, please give us some seconds...',
        words: {
          captureLeads: 'Capture leads for the language course I teach',
          imManager: 'I\'m a manager who wants to capture feedback from my team members',
          understandTheNeeds: 'Understand the needs of customers of an insurance company',
          knowMyCustomers: 'Know what my customers think about my marketing agency services',
          performEmployee: 'Perform employee satisfaction survey',
          collectFeedback: 'Collect feedback from customers of a course I am teaching',
          knowThePains: 'Know the pains of customers of my pizza restaurant'
        }
      },
      theFormNavBar: {
        goBackToWorkspace: 'Go back to the workspace',
        emptyValuePlaceholder: 'Untitled note',
        editPlaceholder: 'What is the title?',
        edit: 'Edit',
        integrate: 'Integrate',
        share: 'Share',
        results: 'Results',
        discardChanges: 'Discard changes',
        discard: 'Discard',
        saveChangesAndTurn: 'Save changes and turn this form public',
        publish: 'Publish',
        title: 'Untitled form'
      }
    },
    layout: {
      theNavbar: {
        title: 'Home'
      },
      theNavbarAlert: {
        yourAccount: 'Your account is suspended. Please make a payment to reactivate your account.',
        makePayment: 'Make a payment'
      },
      theUpgradePlanButton: {
        responsesUsed: ' responses used this month in your ',
        plan: 'plan',
        responses: 'Responses',
        upgrade: 'Upgrade',
        redirecting: 'Redirecting...'
      },
      theUserAvatarDropdown: {
        profile: 'Profile',
        earnMoney: 'Affiliate Program',
        billing: 'Manage Subscription',
        redirecting: 'Redirecting...',
        support: 'Support',
        signOut: 'Sign out'
      },
      theUserAvatarList: {
        show: 'Show ',
        more: ' More',
        membersOf: 'Members of ',
        removeFromWorkspace: 'Remove from workspace...',
        addUserTo: 'Add user to ',
        inviteUserTo: 'Invite user to ',
        sendingInvitation: 'Sending invitation...',
        invite: 'Invite',
        label: 'Email',
        placeholder: 'Email of the user you want to invite',
        userRemoved: 'User removed from workspace!',
        invitationSent: 'Invitation sent!',
        title: 'Remove User',
        message: 'will no longer have access to this workspace.',
        noButtonText: 'Cancel',
        yesButtonText: 'Yes, Remove'
      },
      theWorkspaceSubdomain: {
        subdomain: 'Subdomain'
      },
      theWorkspaceOptions: {
        workspaceOptionsTooltip: 'Workspace options...',
        workspaceOptions: 'Workspace options',
        rename: 'Rename',
        delete: 'Delete',
        title: 'Wait a minute!',
        goingAhead: 'Going ahead means you won\'t be able to access the forms of ',
        anymore: ' anymore. Are you sure you want to leave this workspace?',
        noButtonText: 'No',
        yesButtonText: 'Yes, leave workspace'
      },
      theWorkspaceDropdown: {
        addWorkspace: 'Add workspace...'
      },
      theNotificationsDropdown: {
        title: 'Notifications',
        onboardingTitle: 'Getting Started',
        markAllAsRead: 'Mark all as read',
        noNotifications: 'No notifications',
        noMoreNotifications: 'No more notifications',
        markAsRead: 'Mark as read',
        markAsUnread: 'Mark as unread'
      }
    },
    workspace: {
      modalAddWorkspace: {
        title: 'New workspace',
        workspaceName: 'Workspace name',
        workspaceNamePlaceholder: 'Workspace name...',
        cancel: 'Cancel',
        save: 'save'
      },
      modalDeleteWorkspace: {
        title: 'Delete workspace',
        allForms: 'All forms of this workspace will be deleted. Type ',
        toContinue: ' to continue.',
        workspaceName: 'Workspace name',
        workspaceNamePlaceholder: 'Workspace name...',
        validationMessages: 'Workspace name doesn\'t match.',
        cancel: 'Cancel',
        deleteTheWorkspace: 'Delete the workspace and all of its forms'
      },
      modalRenameWorkspace: {
        title: 'Rename workspace',
        workspaceName: 'Workspace name',
        placeHolderNewWorkspaceName: 'New workspace name...',
        cancel: 'Cancel',
        rename: 'Rename'
      },
      modalChangeSubdomain: {
        title: 'Change subdomain',
        subdomain: 'Subdomain',
        placeHolderSubdomain: 'E.g. mycompany (text before .yayforms.link)',
        help: 'The subdomain must be unique and can only contain letters or numbers. E.g. <strong>mycompany</strong> in <strong>mycompany</strong>.yayforms.link',
        cancel: 'Cancel',
        change: 'Change'
      }
    }
  },
  lib: {
    utils: {
      field: {
        multipleChoice: 'Multiple choice',
        pictureChoice: 'Picture choice',
        opinionScale: 'Opinion Scale',
        yesNo: 'Yes/No',
        nps: 'Net Promoter Score®',
        dropdown: 'Dropdown',
        rating: 'Rating',
        email: 'Email',
        phone: 'Phone',
        date: 'Date',
        website: 'Website',
        shortText: 'Short text',
        longText: 'Long text',
        number: 'Number',
        statement: 'Statement',
        fileUpload: 'File Upload',
        welcomeScreen: 'Welcome Screen',
        endingScreen: 'Ending Screen',
        calendly: 'Calendly',
        choices: 'Choices',
        contactInfo: 'Contact info',
        textNumber: 'Text and number',
        formStructure: 'Form structure',
        file: 'File',
        integrations: 'Integrations'
      },
      logic: {
        jumpTo: 'Go to',
        add: 'Add',
        subtract: 'Subtract',
        multiply: 'Multiply',
        divide: 'Divide',
        by: 'by',
        from: 'from',
        to: 'to',
        is: 'is',
        isNot: 'is not',
        isGreater: 'is greater than',
        isLess: 'is less than',
        isGreaterOrEqual: 'is greater than or equal to',
        isLessOrEqual: 'is less than or equal to',
        contains: 'contains',
        doesntContain: 'doesn\'t contain',
        startsWith: 'starts with',
        endsWith: 'ends with',
        isEmpty: 'is empty',
        isNotEmpty: 'is not empty',
        isIn: 'is in',
        isNotIn: 'is not in',
        isBetween: 'is between',
        isNotBetween: 'is not between',
        isToday: 'is today',
        isYesterday: 'is yesterday',
        isTomorrow: 'is tomorrow',
        isAfter: 'is after',
        isBefore: 'is before',
        isTheGreatest: 'is the greatest one',
        isTheLeast: 'is the least one'
      },
      theme: {
        fields: 'Title and texts',
        answers: 'Answers',
        buttons: 'Background',
        buttonText: 'Text',
        Background: 'Background',
        Font: 'Font'
      },
      i18n: {
        en: 'English',
        es: 'Spanish',
        pt: 'Portuguese (Brazil)'
      }
    }
  },
  AG_GRID_LOCALE: {
    selectAll: '(Select All)',
    selectAllSearchResults: '(Select All Search Results)',
    searchOoo: 'Search...',
    blanks: '(Blanks)',
    noMatches: 'No matches',
    filterOoo: 'Filter...',
    equals: 'Equals',
    notEqual: 'Not equal',
    blank: 'Blank',
    notBlank: 'Not blank',
    empty: 'Choose One',
    lessThan: 'Less than',
    greaterThan: 'Greater than',
    lessThanOrEqual: 'Less than or equal',
    greaterThanOrEqual: 'Greater than or equal',
    inRange: 'In range',
    inRangeStart: 'from',
    inRangeEnd: 'to',
    contains: 'Contains',
    notContains: 'Not contains',
    startsWith: 'Starts with',
    endsWith: 'Ends with',
    dateFormatOoo: 'yyyy-mm-dd',
    andCondition: 'AND',
    orCondition: 'OR',
    applyFilter: 'Apply',
    resetFilter: 'Reset',
    clearFilter: 'Clear',
    cancelFilter: 'Cancel',
    textFilter: 'Text Filter',
    numberFilter: 'Number Filter',
    dateFilter: 'Date Filter',
    setFilter: 'Set Filter',
    groupFilterSelect: 'Select field:',
    columns: 'Columns',
    filters: 'Filters',
    pivotMode: 'Pivot Mode',
    groups: 'Row Groups',
    rowGroupColumnsEmptyMessage: 'Drag here to set row groups',
    values: 'Values',
    valueColumnsEmptyMessage: 'Drag here to aggregate',
    pivots: 'Column Labels',
    pivotColumnsEmptyMessage: 'Drag here to set column labels',
    group: 'Group',
    rowDragRow: 'row',
    rowDragRows: 'rows',
    loadingOoo: 'Loading...',
    loadingError: 'ERR',
    noRowsToShow: 'No Rows To Show',
    enabled: 'Enabled',
    pinColumn: 'Pin Column',
    pinLeft: 'Pin Left',
    pinRight: 'Pin Right',
    noPin: 'No Pin',
    valueAggregation: 'Value Aggregation',
    noAggregation: 'None',
    autosizeThiscolumn: 'Autosize This Column',
    autosizeAllColumns: 'Autosize All Columns',
    groupBy: 'Group by',
    ungroupBy: 'Un-Group by',
    resetColumns: 'Reset Columns',
    expandAll: 'Expand All Row Groups',
    collapseAll: 'Close All Row Groups',
    copy: 'Copy',
    ctrlC: 'Ctrl+C',
    ctrlX: 'Ctrl+X',
    copyWithHeaders: 'Copy With Headers',
    copyWithGroupHeaders: 'Copy with Group Headers',
    cut: 'Cut',
    paste: 'Paste',
    ctrlV: 'Ctrl+V',
    export: 'Export',
    csvExport: 'CSV Export',
    excelExport: 'Excel Export',
    sum: 'Sum',
    first: 'First',
    last: 'Last',
    min: 'Min',
    max: 'Max',
    none: 'None',
    count: 'Count',
    avg: 'Average',
    filteredRows: 'Filtered',
    selectedRows: 'Selected',
    totalRows: 'Total Rows',
    totalAndFilteredRows: 'Rows',
    more: 'More',
    to: 'to',
    of: 'of',
    page: 'Page',
    pageLastRowUnknown: '?',
    nextPage: 'Next Page',
    lastPage: 'Last Page',
    firstPage: 'First Page',
    previousPage: 'Previous Page',
    pivotColumnGroupTotals: 'Total',
    pivotChartAndPivotMode: 'Pivot Chart & Pivot Mode',
    pivotChart: 'Pivot Chart',
    chartRange: 'Chart Range',
    columnChart: 'Column',
    groupedColumn: 'Grouped',
    stackedColumn: 'Stacked',
    normalizedColumn: '100% Stacked',
    barChart: 'Bar',
    groupedBar: 'Grouped',
    stackedBar: 'Stacked',
    normalizedBar: '100% Stacked',
    pieChart: 'Pie',
    pie: 'Pie',
    doughnut: 'Doughnut',
    line: 'Line',
    xyChart: 'X Y (Scatter)',
    scatter: 'Scatter',
    bubble: 'Bubble',
    areaChart: 'Area',
    area: 'Area',
    stackedArea: 'Stacked',
    normalizedArea: '100% Stacked',
    histogramChart: 'Histogram',
    histogramFrequency: 'Frequency',
    combinationChart: 'Combination',
    columnLineCombo: 'Column & Line',
    AreaColumnCombo: 'Area & Column',
    ariaChecked: 'checked',
    ariaColumn: 'Column',
    ariaColumnGroup: 'Column Group',
    ariaColumnList: 'Column List',
    ariaColumnSelectAll: 'Toggle Select All Columns',
    ariaDateFilterInput: 'Date Filter Input',
    ariaDefaultListName: 'List',
    ariaFilterColumnsInput: 'Filter Columns Input',
    ariaFilterFromValue: 'Filter from value',
    ariaFilterInput: 'Filter Input',
    ariaFilterList: 'Filter List',
    ariaFilterToValue: 'Filter to value',
    ariaFilterValue: 'Filter Value',
    ariaFilterMenuOpen: 'Open Filter Menu',
    ariaFilteringOperator: 'Filtering Operator',
    ariaHidden: 'hidden',
    ariaIndeterminate: 'indeterminate',
    ariaInputEditor: 'Input Editor',
    ariaMenuColumn: 'Press CTRL ENTER to open column menu.',
    ariaRowDeselect: 'Press SPACE to deselect this row',
    ariaRowSelectAll: 'Press Space to toggle all rows selection',
    ariaRowToggleSelection: 'Press Space to toggle row selection',
    ariaRowSelect: 'Press SPACE to select this row',
    ariaSearch: 'Search',
    ariaSortableColumn: 'Press ENTER to sort',
    ariaToggleVisibility: 'Press SPACE to toggle visibility',
    ariaToggleCellValue: 'Press SPACE to toggle cell value',
    ariaUnchecked: 'unchecked',
    ariaVisible: 'visible',
    ariaSearchFilterValues: 'Search filter values',
    ariaRowGroupDropZonePanelLabel: 'Row Groups',
    ariaValuesDropZonePanelLabel: 'Values',
    ariaPivotDropZonePanelLabel: 'Column Labels',
    ariaDropZoneColumnComponentDescription: 'Press DELETE to remove',
    ariaDropZoneColumnValueItemDescription: 'Press ENTER to change the aggregation type',
    ariaDropZoneColumnGroupItemDescription: 'Press ENTER to sort',
    ariaDropZoneColumnComponentAggFuncSeperator: ' of ',
    ariaDropZoneColumnComponentSortAscending: 'ascending',
    ariaDropZoneColumnComponentSortDescending: 'descending',
    ariaLabelColumnMenu: 'Column Menu',
    ariaLabelCellEditor: 'Cell Editor',
    ariaLabelDialog: 'Dialog',
    ariaLabelSelectField: 'Select Field',
    ariaLabelTooltip: 'Tooltip',
    ariaLabelContextMenu: 'Context Menu',
    ariaLabelSubMenu: 'SubMenu',
    ariaLabelAggregationFunction: 'Aggregation Function',
    thousandSeparator: ',',
    decimalSeparator: '.',
    true: 'True',
    false: 'False',
    invalidDate: 'Invalid Date',
    invalidNumber: 'Invalid Number',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  }
}
