
import { mapState } from 'vuex'
import notificationsMixin from '~/lib/mixins/notifications.mixin'

export default {
  name: 'NotificationsDropdown',
  mixins: [notificationsMixin],

  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: 'oi-alarm-bell'
    },
    categories: {
      type: Array,
      default: () => []
    },
    excludeCategories: {
      type: Array,
      default: () => []
    },
    alwaysShow: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isClosing: false,
      isActive: false,
      loadingMore: false,
      dropdownId: `notifications-${Math.random().toString(36).substr(2, 9)}`
    }
  },

  computed: {
    ...mapState('notifications', ['unreadCounts', 'notificationsData', 'isFetchingNotifications']),

    notificationsCategory () {
      return this.categories?.includes('onboarding') ? 'onboarding' : 'general'
    },

    notificationsData () {
      return this.$store.state.notifications.notifications
    },

    hasMore () {
      return this.notificationsData[this.notificationsCategory]?.hasMore || false
    },

    isLoading () {
      return this.notificationsData[this.notificationsCategory]?.loading || this.loadingMore
    },

    error () {
      return this.notificationsData[this.notificationsCategory]?.error || null
    },

    notifications () {
      return this.notificationsData[this.notificationsCategory]?.items || []
    },

    unreadCount () {
      if (this.categories?.includes('onboarding')) {
        return this.$store.state.notifications.unreadCounts.onboarding
      } else if (this.excludeCategories?.includes('onboarding')) {
        return this.$store.state.notifications.unreadCounts.general
      }
      return this.$store.state.notifications.unreadCounts.all
    },

    shouldShowDropdown () {
      return true
    }
  },

  methods: {
    getApiParams () {
      const params = {}

      if (this.categories?.length) {
        params.categories = this.categories
      }

      if (this.excludeCategories?.length) {
        params.excludeCategories = this.excludeCategories
      }

      return params
    },

    generateId () {
      return Math.random().toString(36).substr(2, 9)
    },

    async loadMoreNotifications () {
      if (this.loadingMore || !this.hasMore) {
        return
      }

      this.loadingMore = true

      try {
        const params = this.getApiParams()
        const storeCategory = this.notificationsCategory
        const currentPage = this.$store.state.notifications.notifications[storeCategory]?.currentPage || 1
        params.page = currentPage + 1

        await this.$store.dispatch('notifications/fetchCategoryNotifications', params)
      } catch (error) {
        console.error('Error loading more notifications:', error)
      } finally {
        this.loadingMore = false
      }
    },

    handleScroll () {
      if (this.loadingMore || !this.hasMore) {
        return
      }

      const container = this.$refs.notificationsContainer
      if (!container) {
        return
      }

      const { scrollTop, scrollHeight, clientHeight } = container
      const distanceToBottom = scrollHeight - scrollTop - clientHeight
      const threshold = 100

      if (distanceToBottom < threshold) {
        this.loadMoreNotifications()
      }
    },

    async toggleRead (notification) {
      try {
        const isOnboardingDropdown = this.categories?.includes('onboarding')

        if (notification.readAt) {
          await this.$notificationsService.markAsUnread(notification.id)

          if (isOnboardingDropdown) {
            this.$store.commit('notifications/incrementUnreadCount', 'onboarding')
            this.$store.commit('notifications/incrementUnreadCount', 'all')
          } else {
            this.$store.commit('notifications/incrementUnreadCount', 'general')
            this.$store.commit('notifications/incrementUnreadCount', 'all')
          }

          const updatedNotification = { ...notification, readAt: null }
          this.updateNotificationInStore(updatedNotification)
        } else {
          await this.$notificationsService.markAsRead(notification.id)

          if (isOnboardingDropdown) {
            this.$store.commit('notifications/decrementUnreadCount', 'onboarding')
            this.$store.commit('notifications/decrementUnreadCount', 'all')
          } else {
            this.$store.commit('notifications/decrementUnreadCount', 'general')
            this.$store.commit('notifications/decrementUnreadCount', 'all')
          }

          const updatedNotification = { ...notification, readAt: new Date().toISOString() }
          this.updateNotificationInStore(updatedNotification)
        }
      } catch (error) {
        console.error('Error toggling notification read status:', error)
      }
    },

    updateNotificationInStore (updatedNotification) {
      const category = this.notificationsCategory
      const items = [...this.notificationsData[category].items]
      const index = items.findIndex(n => n.id === updatedNotification.id)

      if (index !== -1) {
        items[index] = updatedNotification

        this.$store.commit('notifications/setNotificationsData', {
          category,
          data: { items }
        })
      }
    },

    async markAllAsRead () {
      try {
        const params = this.getApiParams()
        await this.$notificationsService.markAllAsRead(params)

        const isOnboardingDropdown = this.categories?.includes('onboarding')

        if (isOnboardingDropdown) {
          this.$store.commit('notifications/resetUnreadCount', 'onboarding')

          const generalCount = this.$store.state.notifications.unreadCounts.general || 0
          this.$store.commit('notifications/setUnreadCount', {
            category: 'all',
            count: generalCount
          })
        } else {
          this.$store.commit('notifications/resetUnreadCount', 'general')

          const onboardingCount = this.$store.state.notifications.unreadCounts.onboarding || 0
          this.$store.commit('notifications/setUnreadCount', {
            category: 'all',
            count: onboardingCount
          })
        }

        const items = [...this.notifications].map(item => ({
          ...item,
          readAt: item.readAt || new Date().toISOString()
        }))

        this.$store.commit('notifications/setNotificationsData', {
          category: this.notificationsCategory,
          data: { items }
        })
      } catch (error) {
        console.error('Error marking all notifications as read:', error)
      }
    },

    handleNotificationClick (notification, _event) {
      if (notification.data?.link) {
        window.location.href = notification.data.link
      }
    },

    getNotificationIcon (notification) {
      return notification.data?.icon || 'oi-alarm-bell'
    },

    getNotificationIconClass (notification) {
      return notification.data?.iconClass || 'text-primary'
    },

    formatDate (date) {
      return this.$dayjs(date).fromNow()
    },

    toggleDropdown (active) {
      this.isActive = active

      if (active) {
        const storeCategory = this.notificationsCategory
        this.$store.commit('notifications/setNotificationsData', {
          category: storeCategory,
          data: {
            loading: true
          }
        })

        const params = this.getApiParams()
        params.page = 1

        this.$store.dispatch('notifications/fetchCategoryNotifications', params)
      } else {
        this.isClosing = true
        setTimeout(() => {
          this.isClosing = false
        }, 300)
      }
    }
  }
}
