/* eslint-disable prefer-regex-literals */
import useFieldsService from '~/lib/services/api/fields.service'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { removeHtmlTags, letter, replaceAll, removeTagsByAttribute } from '~/lib/utils/string.utils'
import { getDefaultEndingScreen } from '~/lib/utils/logic.utils'

const actions = {
  async addField ({ commit, state }, type) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    let title = ''

    if (type === 'nps') {
      title = 'How likely are you to recommend us to a friend or colleague?'
    }

    const field = await fieldService.create(state.form.id, title, type)

    commit('ADD_FIELD', field)
    commit('UPDATE_FORM_IS_DIRTY', true)

    return field
  },

  async duplicateField ({ state, commit, dispatch }, cloneFieldId) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    const field = await fieldService.duplicate(state.form.id, cloneFieldId)
    const cloneFieldIndex = state.fields.findIndex(f => f.id === cloneFieldId)

    if (cloneFieldIndex > -1) {
      commit('INSERT_FIELD', { field, index: cloneFieldIndex + 1 })
    }

    // Update field positions
    const regularFields = state.fields.filter(field => field.type !== 'welcome-screen' && field.type !== 'ending-screen')
    dispatch('updateFieldsPosition', regularFields)
    commit('UPDATE_FORM_IS_DIRTY', true)

    return field
  },

  async addWelcomeScreenField ({ commit, state }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    const field = await fieldService.create(state.form.id, '', 'welcome-screen')

    commit('ADD_FIELD', field)
    commit('UPDATE_FORM_IS_DIRTY', true)

    return field
  },

  async addEndingScreenField ({ commit, dispatch, state }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    const field = await fieldService.create(state.form.id, '', 'ending-screen')

    commit('ADD_FIELD', field)
    commit('UPDATE_FORM_IS_DIRTY', true)

    dispatch('replaceAllJumpToActions', { fromValue: getDefaultEndingScreen().id, toValue: field.id })

    return field
  },
  removeVariableFieldFromFields ({ state, commit, dispatch }, fieldId) {
    // remove tags span with data-id="${fieldId}" from fields title and description
    // for each field, if title or description contains the variable, update the field
    const dataId = `data-id="${fieldId}"`
    let formMustBeDirty = false

    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      // update title if it contains the fieldId
      if (field.title && field.title.includes(dataId)) {
        const title = removeTagsByAttribute(field.title, 'data-id', fieldId)
        this.$api.fields.update(field.id, { title })
        dispatch('updateFieldTitle', { fieldId: field.id, title })
        formMustBeDirty = true
      }

      // update description if it contains the fieldId
      if (field.description && field.description.includes(dataId)) {
        const description = removeTagsByAttribute(field.description, 'data-id', fieldId)
        this.$api.fields.update(field.id, { description })
        commit('UPDATE_FIELD_DESCRIPTION', { fieldId: field.id, description })
        formMustBeDirty = true
      }
    }

    if (formMustBeDirty) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  },
  async deleteField ({ commit, dispatch, state }, fieldId) {
    dispatch('removeCalendlyPrefill', { type: 'field', item: fieldId })
    dispatch('removeRulesFromFieldLogicContainingFieldId', fieldId)
    dispatch('removeVariableFieldFromFields', fieldId)

    // dispatch('re( que placeAllJumpToActions', { fromValue: fieldId, toValue: getDefaultEndingScreen().id })

    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.delete(fieldId)

    commit('DELETE_FIELD', fieldId)
    commit('UPDATE_FORM_IS_DIRTY', true)

    const regularFields = state.fields.filter(field => field.type !== 'welcome-screen' && field.type !== 'ending-screen')
    dispatch('updateFieldsPosition', regularFields)
    commit('SET_FIRST_FIELD_AS_CURRENT')

    return state.fields[0]
  },

  setCurrentField ({ commit }, fieldId) {
    commit('SET_CURRENT_FIELD_ID', fieldId)
  },

  async addFieldOption ({ commit, state }, fieldId) {
    const field = state.fields.find(field => field.id === fieldId)
    const options = [...field.options]
    const optionsCount = Object.keys(options).length
    const newOption = `Option ${letter(optionsCount)}`
    options.push(newOption)

    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { options })

    commit('UPDATE_FIELD_OPTIONS', { fieldId, options })
    commit('UPDATE_FORM_IS_DIRTY', true)

    return newOption
  },
  async addFieldPictureOption ({ commit, state }, fieldId) {
    const field = state.fields.find(field => field.id === fieldId)
    const options = [...field.options]
    const optionsCount = Object.keys(options).length
    const option = `Option ${letter(optionsCount)}`
    const newOption = { url: '', alt: '', title: option }
    options.push(newOption)

    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { options })

    commit('UPDATE_FIELD_OPTIONS', { fieldId, options })
    commit('UPDATE_FORM_IS_DIRTY', true)

    return newOption
  },

  async deleteFieldOption ({ commit, dispatch, state }, { fieldId, optionIndex }) {
    const field = state.fields.find(field => field.id === fieldId)
    const options = [...field.options]

    dispatch('removeRulesFromFieldLogicContainingOption', options[optionIndex])

    options.splice(optionIndex, 1)

    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { options })

    commit('UPDATE_FIELD_OPTIONS', { fieldId, options })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldOption ({ commit, state, dispatch }, { fieldId, optionIndex, optionValue }) {
    const field = state.fields.find(field => field.id === fieldId)
    const options = [...field.options]

    await dispatch('updateRulesFromFieldLogicContainingOption', { oldOption: options[optionIndex], newOption: optionValue })

    options[optionIndex] = optionValue

    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { options })

    commit('UPDATE_FIELD_OPTIONS', { fieldId, options })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldOptions ({ commit }, { fieldId, options }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { options })

    commit('UPDATE_FIELD_OPTIONS', { fieldId, options })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  updateFieldsPosition ({ commit }, fields) {
    const fieldService = useFieldsService({ $axios: this.$axios })

    for (let i = 0; i < fields.length; i++) {
      const position = i + 1
      fieldService.update(fields[i].id, { position })
    }

    commit('REORDER_FIELDS', fields)
    commit('UPDATE_FORM_IS_DIRTY', true)
  },
  replaceTitleVariableNameOnFields ({ state, commit }, { fieldId, value }) {
    const dataId = fieldId => `data-id="${fieldId}"`

    const regex = new RegExp('data-id=".*?" data-label=".*?" data-mention-type=".*?">(.*?)</span>', 'g')
    const regexWithContenteditableTrue = new RegExp(`data-id="${fieldId}" data-label=".*?" data-mention-type="field" contenteditable="true">(.*?)</span>`, 'g')
    const regexWithContenteditableFalse = new RegExp(`data-id="${fieldId}" data-label=".*?" data-mention-type="field" contenteditable="false">(.*?)</span>`, 'g')
    const regexWithoutContenteditable = new RegExp(`data-id="${fieldId}" data-label=".*?" data-mention-type="field">(.*?)</span>`, 'g')
    const replaceContent = (content) => {
      const TitleWithMentions = removeHtmlTags(value.replace(regex, 'data-id=".*?" data-label="__" data-mention-type="field" contenteditable="false">__</span>'))
      content = content.replace(regexWithContenteditableTrue, `data-id="${fieldId}" data-label="${TitleWithMentions}" data-mention-type="field" contenteditable="true">${TitleWithMentions}</span>`)
      content = content.replace(regexWithContenteditableFalse, `data-id="${fieldId}" data-label="${TitleWithMentions}" data-mention-type="field" contenteditable="false">${TitleWithMentions}</span>`)
      content = content.replace(regexWithoutContenteditable, `data-id="${fieldId}" data-label="${TitleWithMentions}" data-mention-type="field">${TitleWithMentions}</span>`)

      return content
    }

    let formMustBeDirty = false

    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      // update title if it contains the oldName
      if (field.title && field.title.includes(dataId(fieldId))) {
        const title = replaceContent(field.title)
        this.$api.fields.update(field.id, { title })
        commit('UPDATE_FIELD_TITLE', { fieldId: field.id, title })
        formMustBeDirty = true
      }

      // update description if it contains the oldName
      if (field.description && field.description.includes(dataId(fieldId))) {
        const description = replaceContent(field.description)
        this.$api.fields.update(field.id, { description })
        commit('UPDATE_FIELD_DESCRIPTION', { fieldId: field.id, description })
        formMustBeDirty = true
      }
    }

    if (formMustBeDirty) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  },
  async updateFieldTitle ({ commit, dispatch }, { fieldId, title }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { title })
    await dispatch('replaceTitleVariableNameOnFields', { fieldId, value: title })
    commit('UPDATE_FIELD_TITLE', { fieldId, title })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldDescription ({ commit }, { fieldId, description }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { description })
    commit('UPDATE_FIELD_DESCRIPTION', { fieldId, description })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldType ({ commit }, { fieldId, type }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    const options = fieldService.getDefaultOptions(type)
    const field = await fieldService.update(fieldId, { type, options })

    commit('UPDATE_FIELD', field)
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldSetting ({ commit, state }, { fieldId, setting, value }) {
    const field = state.fields.find(field => field.id === fieldId)
    const settings = { ...field.settings }
    settings[setting] = value

    const fieldService = useFieldsService({ $axios: this.$axios })
    const { options } = await fieldService.update(fieldId, { settings })

    commit('UPDATE_FIELD_SETTINGS', { fieldId, settings })
    commit('UPDATE_FIELD_OPTIONS', { fieldId, options })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldRequired ({ commit }, { fieldId, required }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { required })

    commit('UPDATE_FIELD_REQUIRED', { fieldId, required })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldLayout ({ commit }, { fieldId, layout }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { layout })

    commit('UPDATE_FIELD_LAYOUT', { fieldId, layout })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateFieldLogic ({ commit }, { fieldId, logic }) {
    const fieldService = useFieldsService({ $axios: this.$axios })
    await fieldService.update(fieldId, { logic })

    commit('UPDATE_FIELD_LOGIC', { fieldId, logic })
    commit('UPDATE_FORM_IS_DIRTY', true)
  },
  removeCalendlyPrefill ({ state, commit }, info) {
    const calendlyFieldsWithSpecificPrefill = state.fields.filter(field =>
      field.type === 'calendly' &&
        (field.settings.prefillNameField === `type='${info.type}' id='${info.item}'>${info.item}<` ||
         field.settings.prefillEmailField === `type='${info.type}' id='${info.item}'>${info.item}<`)
    )

    let formUpdated = false
    calendlyFieldsWithSpecificPrefill.forEach((field) => {
      const updatedSettings = { ...field.settings }

      if (field.settings.prefillNameField === `type='${info.type}' id='${info.item}'>${info.item}<`) {
        updatedSettings.prefillNameField = null
      }
      if (field.settings.prefillEmailField === `type='${info.type}' id='${info.item}'>${info.item}<`) {
        updatedSettings.prefillEmailField = null
      }
      this.$api.fields.update(field.id, { settings: updatedSettings })
      commit('UPDATE_FIELD_SETTINGS', { fieldId: field.id, settings: updatedSettings })

      formUpdated = true
    })

    if (formUpdated) {
      commit('UPDATE_FORM_IS_DIRTY', true)
    }
  },
  removeRulesFromFieldLogicContainingFieldId ({ state, commit }, fieldId) {
    // When a field is deleted, all rules containing the fieldId should be removed from all fields
    const anyActionUseFieldId = rule => rule.actions.some(action => action.function === 'jumpTo' && action.value === fieldId)
    const anyConditionUseFieldId = rule => rule.conditions.some(condition => condition.fieldId === fieldId)
    const fieldsWithThisFieldIdInLogic = state.fields.filter(field => (field.logic || []).some(rule => anyActionUseFieldId(rule) || anyConditionUseFieldId(rule)))

    fieldsWithThisFieldIdInLogic.forEach((field) => {
      // remove rules containing the fieldId from the field logic
      let logic = [...field.logic.filter(rule => !(anyActionUseFieldId(rule) || anyConditionUseFieldId(rule)))]

      if (logic.length === 0) { logic = null }

      this.$api.fields.update(field.id, { logic })
      commit('UPDATE_FIELD_LOGIC', { fieldId: field.id, logic })
    })

    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  removeRulesFromFieldLogicContainingOption ({ state, commit }, option) {
    // When an option is deleted, all rules containing the option with eq or notEq operator should be removed from all fields
    const anyConditionUse = rule => rule.conditions.some(condition => ['eq', 'notEq'].includes(condition.operator) && condition.value === removeHtmlTags(option))
    const fieldsWithThisOptionInLogic = state.fields.filter(field => (field.logic || []).some(rule => anyConditionUse(rule)))

    fieldsWithThisOptionInLogic.forEach((field) => {
      // remove rules containing the option from the field logic
      let logic = [...field.logic.filter(rule => !(anyConditionUse(rule)))]

      if (logic.length === 0) { logic = null }

      this.$api.fields.update(field.id, { logic })
      commit('UPDATE_FIELD_LOGIC', { fieldId: field.id, logic })
    })

    commit('UPDATE_FORM_IS_DIRTY', true)
  },

  async updateRulesFromFieldLogicContainingOption ({ state, commit }, { oldOption, newOption }) {
    // When an option is renamed, all rules containing the option with eq or notEq operator should be updated to use the new option
    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      if (!Array.isArray(field.logic)) {
        continue
      }

      const logic = JSON.parse(JSON.stringify(field.logic))
      let updateLogic = false

      // update rules containing the oldOption from the field logic
      for (let i2 = 0; i2 < logic.length; i2++) {
        const rule = logic[i2]

        for (let i3 = 0; i3 < rule.conditions.length; i3++) {
          const condition = rule.conditions[i3]

          if (['eq', 'notEq'].includes(condition.operator) && condition.value === removeHtmlTags(oldOption)) {
            logic[i2].conditions[i3].value = removeHtmlTags(newOption)
            updateLogic = true
          }
        }
      }

      if (updateLogic) {
        await this.$api.fields.update(field.id, { logic })
        commit('UPDATE_FIELD_LOGIC', { fieldId: field.id, logic })
      }
    }
  },

  replaceAllJumpToActions ({ state, commit }, { fromValue, toValue }) {
    for (let i = 0; i < state.fields.length; i++) {
      const field = state.fields[i]

      if (!Array.isArray(field.logic)) {
        continue
      }

      const logic = JSON.parse(JSON.stringify(field.logic))
      let updateLogic = false

      // update rules containing the fromValue from the field logic
      for (let i2 = 0; i2 < logic.length; i2++) {
        const rule = logic[i2]

        for (let i3 = 0; i3 < rule.actions.length; i3++) {
          const action = rule.actions[i3]

          if (action.function === 'jumpTo' && action.value === fromValue) {
            logic[i2].actions[i3].value = toValue
            updateLogic = true
          }
        }
      }

      if (updateLogic) {
        this.$api.fields.update(field.id, { logic })
        commit('UPDATE_FIELD_LOGIC', { fieldId: field.id, logic })
      }
    }
  }
}

const mutations = {
  SET_FIELDS (state, fields) {
    state.fields = fields
  },

  SET_CURRENT_FIELD_ID (state, fieldId) {
    state.currentFieldId = fieldId
  },

  SET_FIRST_FIELD_AS_CURRENT (state) {
    state.currentFieldId = state.fields[0]?.id
  },

  UPDATE_FIELD (state, field) {
    const elementIndex = state.fields.findIndex(element => element.id === field.id)
    state.fields.splice(elementIndex, 1, field)
  },

  REORDER_FIELDS (state, fields) {
    const welcomeAndEndingFields = state.fields.filter(field => field.type === 'welcome-screen' || field.type === 'ending-screen')
    const regularFields = fields.filter(field => field.type !== 'welcome-screen' && field.type !== 'ending-screen')

    for (let i = 0; i < regularFields.length; i++) {
      regularFields[i].position = i + 1
    }

    state.fields = [...welcomeAndEndingFields, ...regularFields] // .sort((a, b) => a.position - b.position)
  },

  UPDATE_FIELD_POSITION (state, { fieldId, position }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.position = position
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_OPTIONS (state, { fieldId, options }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.options = options
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_TITLE (state, { fieldId, title }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.title = title
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_DESCRIPTION (state, { fieldId, description }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.description = description
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_TYPE (state, { fieldId, type, options }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.type = type
    field.options = options
    state.fields.splice(elementIndex, 1, field)
  },

  DELETE_FIELD (state, fieldId) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    state.fields.splice(elementIndex, 1)
  },

  ADD_FIELD (state, field) {
    state.fields.push(field)
  },

  INSERT_FIELD (state, { field, index }) {
    state.fields.splice(index, 0, field)
  },

  UPDATE_FIELD_SETTINGS (state, { fieldId, settings }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.settings = settings
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_REQUIRED (state, { fieldId, required }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.required = required
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_LAYOUT (state, { fieldId, layout }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.layout = layout
    state.fields.splice(elementIndex, 1, field)
  },

  UPDATE_FIELD_LOGIC (state, { fieldId, logic }) {
    const elementIndex = state.fields.findIndex(element => element.id === fieldId)
    const field = state.fields[elementIndex]
    field.logic = logic
    state.fields.splice(elementIndex, 1, field)
  }
}

const getters = {
  getCurrentField (state) {
    return state.fields.find(field => field.id === state.currentFieldId) || null
  },
  getNextField (state) {
    const sortedFields = [...state.fields]
    sortedFields.sort((a, b) => a.position - b.position)
    const currentFieldIndex = sortedFields.findIndex(field => field.id === state.currentFieldId)
    const endingScreen = state.fields.find(field => field.type === 'ending-screen')
    let nextFieldIndex = currentFieldIndex + 1
    const nextField = sortedFields[nextFieldIndex]

    // if is welcome screen then skip next field if it is an ending screen because ending screen has position = 0
    if (sortedFields[currentFieldIndex].type === 'welcome-screen' && (nextField && nextField.type === 'ending-screen')) {
      nextFieldIndex++
    }

    return sortedFields[nextFieldIndex] || endingScreen || null
  },
  getLastField (state) {
    const endingScreen = state.fields.find(field => field.type === 'ending-screen')
    if (endingScreen) { return endingScreen }

    const sortedFields = [...state.fields]
    sortedFields.sort((a, b) => a.position - b.position)
    const currentFieldIndex = sortedFields.findIndex(field => field.id === state.currentFieldId)
    return sortedFields[currentFieldIndex + 1] || null
  },
  getFieldsBefore: state => (fieldId) => {
    const currentField = state.fields.find(field => field.id === fieldId)
    const ignoredFieldTypes = ['welcome-screen', 'ending-screen', 'statement']

    if (currentField.type === 'ending-screen') {
      return state.fields.filter(field => field.type !== 'welcome-screen' && field.type !== 'ending-screen')
    }

    return state.fields.filter(field => field.position <= currentField.position && !ignoredFieldTypes.includes(field.type))
  },
  getFieldsAfter: state => (fieldId) => {
    const currentField = state.fields.find(field => field.id === fieldId)
    const fields = state.fields.filter(field => field.position > currentField.position)
    const endingScreens = state.fields.filter(field => field.type === 'ending-screen')
    return fields.concat(endingScreens)
  },
  getFieldById: state => (fieldId) => {
    return state.fields.find(field => field.id === fieldId) || null
  },
  getWelcomeScreens (state) {
    return state.fields.filter(field => field.type === 'welcome-screen')
  },
  getEndingScreens (state) {
    return state.fields.filter(field => field.type === 'ending-screen')
  },
  getQuestions (state) {
    return state.fields.filter(field => field.type !== 'welcome-screen' && field.type !== 'ending-screen')
  },
  getAnswerableFields (state) {
    const notAnswerableTypes = ['welcome-screen', 'ending-screen', 'statement']

    return state.fields.filter(field => !notAnswerableTypes.includes(field.type))
  },
  getFieldsThatCanAddInsights (state) {
    return state.fields.filter(field => field.canAddInsights === true)
  },
  isUsedInLogic: state => (fieldId) => {
    const anyActionUse = logic => logic.some(rule => rule.actions.some(action => action.function === 'jumpTo' && action.value === fieldId))
    const anyConditionUse = logic => logic.some(rule => rule.conditions.some(condition => condition.fieldId === fieldId))

    return state.fields.some(field => field.logic && (anyActionUse(field.logic) || anyConditionUse(field.logic)))
  },
  isOptionUsedInLogic: state => (option) => {
    const anyConditionUse = logic => logic.some(rule => rule.conditions.some(condition => ['eq', 'notEq'].includes(condition.operator) && condition.value === removeHtmlTags(option)))

    return state.fields.some(field => field.logic && (anyConditionUse(field.logic)))
  }
}

export default {
  actions,
  mutations,
  getters
}
