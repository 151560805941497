import createRepository from './repository/create-repository'

export default ({ $axios }) => {
  const repository = createRepository({ $axios, resource: 'responses' })

  /**
   * Returns all responses of a given workspace.
   *
   * @param {String} formId
   * @param {Boolean} onlySubmitted
   * @param {String} paginationToken
   * @param {Number} limit
   * @param {Object} sortModel
   * @param {Object} filterModel
   * @returns {Promise<Array<{Object}>>} Returns an array of responses.
   */
  async function find (formId, { onlySubmitted, page, paginationToken, limit, sortModel, filterModel }) {
    const repo = createRepository({ $axios, resource: 'responsesApi' })

    const limitParam = limit !== undefined ? `&limit=${limit}` : ''
    const pageParam = page !== undefined ? `&page=${page}` : ''
    const paginationTokenParam = paginationToken ? `&paginationToken=${paginationToken}` : ''
    const submittedParam = onlySubmitted !== undefined ? `&submitted=${onlySubmitted}` : ''
    const sortModelParam = sortModel ? `&sortModel=${JSON.stringify(sortModel)}` : ''
    const filterModelParam = filterModel ? `&filterModel=${JSON.stringify(filterModel)}` : ''
    const endpoint = `forms/${formId}/responses?${submittedParam}${pageParam}${paginationTokenParam}${limitParam}${sortModelParam}${filterModelParam}`.replace('?&', '?')

    return await repo.find(endpoint)
  }

  /**
   * Returns all responses of a given workspace.
   *
   * @param {String} formId
   * @returns {Promise<Array<{Object}>>} Returns an array of responses.
   */
  async function download (formId, fileType) {
    const fileTypes = {
      csv: 'text/csv',
      xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      xls: 'application/vnd.ms-excel',
      pdf: 'application/pdf',
      json: 'application/json'
    }

    if (!fileTypes[fileType]) { return }

    return await $axios.$get(`forms/${formId}/responses`, {
      data: {},
      responseType: fileType === 'json' ? 'json' : 'blob',
      headers: {
        'Content-Type': fileTypes[fileType]
      }
    })
  }

  /**
   * Returns a response with the given responseId.
   *
   * @param {String} responseId
   * @returns {Promise<{Object}>} Returns a response.
   */
  async function findOne (responseId) {
    return await repository.findOne('responses', responseId)
  }

  /**
   * Creates a new response.
   *
   * @param {String} formId
   * @param {String} referer
   * @returns {Promise<Object>} Returns the new response created.
   */
  async function create (formId, referer) {
    return (await repository.create(`forms/${formId}/responses`, { referer })).data
  }

  /**
   * Updates a response.
   *
   * @param {String} responseId
   * @param {String} refererUrl
   * @returns {Promise<Object>} Returns the updated response.
   */
  async function update (responseId, refererUrl) {
    return await repository.update('responses', responseId, { refererUrl })
  }

  /**
   * Sets the deletedAt attribute of the given response to the current date and time.
   *
   * @param {String} responseId
   * @returns {Promise<string>} Returns the id of the deleted response.
   */
  async function del (responseId) {
    return await repository.delete('responses', responseId)
  }

  return {
    create,
    delete: del,
    download,
    find,
    findOne,
    update
  }
}
