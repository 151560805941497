
const icons = {
  info: 'oi-information-circle',
  'info-solid': 'oi-information-circle',
  warning: 'oi-alert-circle',
  danger: 'oi-alert-circle',
  error: 'oi-remove-circle-2',
  success: 'oi-check-circle-1',
  primary: 'oi-information-circle',
  accent: 'oi-information-circle'
}

export default {
  props: {
    closable: {
      type: Boolean,
      required: false,
      default: false
    },
    showArrow: {
      type: Boolean,
      required: false,
      default: false
    },
    message: {
      type: String,
      required: false,
      default: ''
    },
    variant: {
      type: String,
      required: false,
      default: 'info',
      validator: value => Object.prototype.hasOwnProperty.call(icons, value)
    }
  },

  computed: {
    icon () {
      return icons[this.variant]
    },
    hasSlotData () {
      return this.$slots.default
    }
  }
}
