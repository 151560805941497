export const state = () => ({
  unreadCounts: {
    all: 0,
    onboarding: 0,
    general: 0
  },
  notifications: {
    general: {
      items: [],
      loading: false,
      error: null,
      hasMore: true,
      lastUnread: null,
      currentPage: 1
    },
    onboarding: {
      items: [],
      loading: false,
      error: null,
      hasMore: true,
      lastUnread: null,
      currentPage: 1
    }
  },
  isFetchingNotifications: false
})

export const mutations = {
  incrementUnreadCount (state, category = 'all') {
    if (state.unreadCounts[category] !== undefined) {
      state.unreadCounts = {
        ...state.unreadCounts,
        [category]: state.unreadCounts[category] + 1
      }
    }
  },

  decrementUnreadCount (state, category = 'all') {
    if (state.unreadCounts[category] !== undefined && state.unreadCounts[category] > 0) {
      state.unreadCounts = {
        ...state.unreadCounts,
        [category]: state.unreadCounts[category] - 1
      }
    }
  },

  setUnreadCount (state, { category = 'all', count }) {
    state.unreadCounts = {
      ...state.unreadCounts,
      [category]: count
    }
  },

  resetUnreadCount (state, category = 'all') {
    state.unreadCounts = {
      ...state.unreadCounts,
      [category]: 0
    }
  },

  setNotificationsData (state, { category, data }) {
    state.notifications = {
      ...state.notifications,
      [category]: {
        ...state.notifications[category],
        ...data
      }
    }
  },

  setIsFetchingNotifications (state, value) {
    state.isFetchingNotifications = value
  }
}

export const actions = {
  updateUnreadCountsFromUser ({ commit, rootState }) {
    try {
      if (rootState.auth.user?.notificationsSummary) {
        const { unreadGeneralNotifications, unreadOnboardingNotifications } = rootState.auth.user.notificationsSummary

        commit('setUnreadCount', {
          category: 'all',
          count: unreadGeneralNotifications + unreadOnboardingNotifications || 0
        })

        commit('setUnreadCount', {
          category: 'general',
          count: unreadGeneralNotifications || 0
        })

        commit('setUnreadCount', {
          category: 'onboarding',
          count: unreadOnboardingNotifications || 0
        })
      }
    } catch (error) {
      console.error('Error updating unread counts from user:', error)
    }
  },

  async fetchCategoryNotifications ({ commit, state }, { categories = null, excludeCategories = [], page = 1 }) {
    try {
      commit('setIsFetchingNotifications', true)

      const params = {
        page
      }

      if (categories) {
        params.categories = categories
      }

      if (excludeCategories && excludeCategories.length > 0) {
        params.excludeCategories = excludeCategories
      }

      const notifications = await this.$notificationsService.find(params)

      const hasMorePages = notifications.meta?.has_more_pages ||
                           (notifications.meta?.current_page < notifications.meta?.last_page) ||
                           false

      const isOnboarding = categories && Array.isArray(categories) && categories.includes('onboarding')
      const storeCategory = isOnboarding ? 'onboarding' : 'general'

      if (page === 1) {
        commit('setNotificationsData', {
          category: storeCategory,
          data: {
            items: notifications.data,
            loading: false,
            error: null,
            hasMore: hasMorePages,
            currentPage: page
          }
        })
      } else {
        const existingItems = state.notifications[storeCategory]?.items || []
        commit('setNotificationsData', {
          category: storeCategory,
          data: {
            items: [...existingItems, ...notifications.data],
            loading: false,
            error: null,
            hasMore: hasMorePages,
            currentPage: page
          }
        })
      }

      return notifications
    } catch (error) {
      console.error('Error fetching notifications:', error)

      const isOnboarding = categories && Array.isArray(categories) && categories.includes('onboarding')
      const storeCategory = isOnboarding ? 'onboarding' : 'general'

      commit('setNotificationsData', {
        category: storeCategory,
        data: {
          loading: false,
          error: 'Error fetching notifications'
        }
      })
      throw error
    } finally {
      commit('setIsFetchingNotifications', false)
    }
  },

  async fetchAllNotificationsData ({ dispatch }) {
    await dispatch('fetchCategoryNotifications', {
      excludeCategories: ['onboarding']
    })

    await dispatch('fetchCategoryNotifications', {
      categories: ['onboarding']
    })
  },

  async fetchUnreadCount ({ commit }, { category = 'all', params = {} } = {}) {
    try {
      const count = await this.$notificationsService.getUnreadCount(params)
      commit('setUnreadCount', { category, count })
    } catch (error) {
      commit('setUnreadCount', { category, count: 0 })
    }
  }
}
