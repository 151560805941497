
import NotificationsDropdown from '~/components/notifications/NotificationsDropdown.vue'

export default {
  name: 'TheNotificationsDropdown',
  components: {
    NotificationsDropdown
  },
  mounted () {
    this.$store.dispatch('notifications/updateUnreadCountsFromUser')
  }
}
